import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser'
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import Anchor from "../../../components/ui/anchor";

import Button from '../../../components/ui/button'
import SectionTitle from '../../../components/ui/section-title'
import {AboutWrapper, LeftBox, RightBox} from './about-area.style'

const AboutDesc = ({sectionTitleStyle, headingStyle, headingTwoStyle, textStyle, linkStyle, descStyle, btnStyle}) => {
    const AboutDescData = useStaticQuery(graphql `
        query AboutPageDescQuery {
            aboutUsJson(id: {eq: "about-page-intro"}) {
                section_title {
                    title
                    subtitle
                }
                heading
                text
                desc
                path
            }
        }
    `);
    const {section_title: {title, subtitle}, heading, text, desc, path} = AboutDescData.aboutUsJson;
    return (
      <AboutWrapper>
        <Container>
          <Row>
            <Col lg={7} md={9} ml="auto" mr="auto">
              <SectionTitle
                {...sectionTitleStyle}
                title={title}
                subtitle={subtitle}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {/* <Heading as="h4" mb="20px">
                We’ve come a long way in 25 years
              </Heading> */}
              <Text {...descStyle}>
                Improving a business while managing the day-to-day is a common
                challenge for many business owners. Whether it’s market
                expansion, business performance or higher profits, Evolve
                business mentors will equip you with the knowledge and
                confidence to get you where you want to be.)
              </Text>
              <Text {...descStyle}>
                We are able to appreciate the demands placed upon
                owner/entrepreneurs to meet stakeholder expectations, to remain
                competitive as a business whilst effectively managing resources
                to deliver the most value to customers.
              </Text>
              <Text {...descStyle}>
                Our intervention philosophy is one which balances your
                expectations as a valued client and the dynamics of your
                business environment with solid management techniques.
              </Text>
              <Text {...descStyle}>
                We want to help you achieve your objectives as well as orientate
                you with the thinking required to meet the demands you face.{" "}
                <br />
                While our focus is to see businesses grow and expand in a
                responsible fashion, we maintain a holistic approach to your
                business needs. We act as a sounding board for your ideas and
                will help you clarify your vision.
              </Text>
            </Col>
            <Col lg={12}>
              <br />
              <Heading {...headingTwoStyle}>
                Challenges are just opportunities in disguise.
                <Anchor {...linkStyle} path="/contact-us">
                  Take the challenge!
                </Anchor>
              </Heading>
              <br />
            </Col>
            {/* <Col lg={{span: 4, offset: 1}}>
                        <LeftBox>
                            {heading && <Heading {...headingStyle}>{parse(heading)}</Heading>}
                            {text && <Heading {...textStyle}>{parse(text)}</Heading>}
                        </LeftBox>
                    </Col>
                    <Col lg={{span: 5, offset: 1}}>
                        <RightBox>
                            {desc && <Text {...descStyle}>{parse(desc)}</Text>}
                            {path && <Button {...btnStyle} to={`/${path}`}>Discover More</Button>}
                        </RightBox>
                    </Col> */}
          </Row>
        </Container>
      </AboutWrapper>
    );
}

AboutDesc.propTypes = {
  sectionStyle: PropTypes.object,
  sectionTitleStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  headingTwoStyle: PropTypes.object,
  textStyle: PropTypes.object,
  descStyle: PropTypes.object,
  linkStyle: PropTypes.object,
};

AboutDesc.defaultProps = {
  sectionTitleStyle: {
    mb: "70px",
    responsive: {
      small: {
        mb: "30px",
      },
    },
  },
  headingStyle: {
    as: "h3",
    layout: "highlight",
    maxwidth: "330px",
    mb: "24px",
    responsive: {
      medium: {
        maxwidth: "100%",
      },
    },
    },
  headingTwoStyle: {
        as: 'h3',
        fontSize: '18px',
        fontweight: 500,
        lineHeight: 1.40,
        color: 'textColor',
        mt: '10px',
        textalign: 'center',
        responsive: {
            small: {
                mt: '5px'
            }
        }
    },
  linkStyle: {
    layout: "underline",
    hover: {
      layout: 2,
    },
  },
  textStyle: {
    as: "h4",
    fontSize: "20px",
  },
  descStyle: {
    fontSize: "18px",
    lineHeight: 1.67,
    mb: "23px",
  },
  btnStyle: {
    varient: "texted",
    iconname: "far fa-long-arrow-right",
    hover: "false",
  },
};

export default AboutDesc;